import React, { Component } from 'react'
import LeftPane from '../LeftPane/index.jsx'
import RightPane from '../RightPane/index.jsx'
import ContactForm from '../ContactForm/index.jsx'

class Contract extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div id='contactPage' className='contact_page'>
        <LeftPane>
          <div className='page_title'>
            <p>C O N T A C T</p>
          </div>
        </LeftPane>
        <div className='contact_page_content'>
          <div className='contact_inner_wrapper'>
            <div className='contact_form_wrapper'>
              <h2>Let's Get In Touch</h2>
              <ContactForm />
            </div>
          </div>
        </div>
        <RightPane invert />
      </div>
    )
  }
}

export default Contract
