// TODO: Figure out how you are going to get the page title to display when the user scrolls.
// TODO: Figure out menu
import React from 'react'
import LandingPage from './Components/LandingPage/index.jsx'
import About from './Components/AboutPage/index.jsx'
import Interest from './Components/InterestPage/index.jsx'
import Contact from './Components/ContactPage/index.jsx'

import './App.css'

function App () {
  return (
    <div className='App-parent'>
      <div className='content-pane'>
        <LandingPage />
        <About />
        <Interest />
        <Contact />
      </div>
    </div>
  )
}

export default App
