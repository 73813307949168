import React, { Component, cloneElement } from 'react'
import { validateInput } from '../../resources/validation.js'

class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      stage: 0,
      formInfo: {
        personName: '',
        email: '',
        subject: '',
        message: ''
      },
      errorMessages: {
        personName: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  }
  inputObject = [
    {
      type: 'text',
      id: 'personName',
      name: 'personName',
      placeholder: 'Enter Your Name',
      required: 'required',
      onChange: e => {
        this.onChange(e)
      },
      onKeyPress: e => {
        this.checkForEnter(e)
      },
      className: null
    },
    {
      type: 'text',
      id: 'email',
      name: 'email',
      placeholder: 'Enter Email Address',
      required: 'required',
      onChange: e => {
        this.onChange(e)
      },
      onKeyPress: e => {
        this.checkForEnter(e)
      },
      className: null
    },
    {
      type: 'text',
      id: 'subject',
      name: 'subject',
      placeholder: 'Subject',
      required: 'required',
      onChange: e => {
        this.onChange(e)
      },
      onKeyPress: e => {
        this.checkForEnter(e)
      },
      className: null
    }
  ]
  errorMessages = {
    personName: 'Please Enter Your Name, No special characters',
    email: 'Please enter email address'
  }

  goBack = e => {
    e.preventDefault()
    let stage = this.state.stage
    let component = this
    if (stage === this.inputObject.length) {
      let textArea = document.getElementById('message')
      textArea.classList['value'] = 'shrink'
      setTimeout(function () {
        let nextPage = stage - 1
        component.setState({ stage: nextPage })
      }, 1000)
    } else {
      let nextPage = this.state.stage - 1
      this.setState({ stage: nextPage })
    }
  }
  submitInfo = e => {
    e.preventDefault()
    let { personName, email, subject, message } = this.state.formInfo

    const templateId = 'mattstandev'
    this.sendFeedback(templateId, {
      from_name: personName,
      reply_to: email,
      subject: subject,
      message_html: message
    })
    console.log(this.state.formInfo)
  }
  sendFeedback = (templateId, variables) => {
    window.emailjs
      .send('outlook', templateId, variables)
      .then(res => {
        console.log('Email sent successfully')
        this.setState({
          stage: 0,
          formInfo: {
            personName: '',
            email: '',
            subject: '',
            message: ''
          }
        })
      })
      .catch(err => {
        console.error('Unsuccessful delivery:', err)
      })
  }
  onChange = e => {
    let newState = Object.assign(this.state.formInfo, {
      [e.target.id]: e.target.value
    })
    this.setState({ formInfo: newState })
  }
  checkForEnter = e => {
    if (e.key == 'Enter' || e.key == 'enter') {
      this.validate(e, this.state.stage)
    }
  }
  renderToggles = () => {
    let stage = this.state.stage

    if (stage === 0) {
      return (
        <span className='button_first-next '>
          <button className='hidden'> None </button>
          <button onClick={e => this.validate(e, stage)}>Next</button>
        </span>
      )
    } else if (stage === this.inputObject.length - 1) {
      return (
        <span>
          <button onClick={e => this.goBack(e)}>Back</button>
          <button onClick={e => this.submitInfo(e)}>Submit</button>
        </span>
      )
    } else {
      return (
        <span>
          <button onClick={e => this.goBack(e)}>Back</button>
          <button onClick={e => this.validate(e, stage)}>Next</button>
        </span>
      )
    }
  }
  renderError = inputName => {
    return (
      <p className='contactErrorMessage'>
        {this.state.errorMessages[inputName]}
      </p>
    )
  }
  validate = (e, index) => {
    e.preventDefault()

    let { id, value } = document.getElementById(this.inputObject[index].id)
    console.log('validate', document.getElementById(this.inputObject[index].id))
    let result = validateInput(id, value)
    if (result === false) {
      let newState = Object.assign(this.state.errorMessages, {
        [id]: this.errorMessages[id]
      })

      this.setState({ errorMessages: newState })
    } else if (result === true) {
      let newState = Object.assign(this.state.errorMessages, {
        [id]: undefined
      })
      this.setState({
        stage: this.state.stage + 1,
        errorMessages: newState
      })
    }
  }

  renderInputs = () => {
    let { stage, formInfo } = this.state
    // eslint-disable-next-line default-case
    switch (stage) {
      case 0:
        return (
          <div className='input_container'>
            <span>
              <input {...this.inputObject[stage]} value={formInfo.personName} />
            </span>
            {this.renderError('personName', stage)}
            {this.renderToggles()}
          </div>
        )
      case 1:
        return (
          <div className='input_container'>
            <span>
              <input {...this.inputObject[stage]} value={formInfo.email} />
            </span>
            {this.renderError('email', stage)}
            {this.renderToggles()}
          </div>
        )
      case 2:
        return (
          <div className='input_container message'>
            <span>
              <input {...this.inputObject[stage]} value={formInfo.subject} />
            </span>
            <textarea
              id='message'
              name='message'
              placeholder='Insert Message Here'
              value={formInfo.message}
              onChange={e => this.onChange(e)}
            />
            {this.renderToggles()}
          </div>
        )
    }
  }

  render () {
    return (
      <form
        id='contact-matthew-form'
        action=''
        onSubmit={e => e.preventDefault()}
      >
        <input type='hidden' name='contact-number' />
        {this.renderInputs(this.state.stage)}
      </form>
    )
  }
}

export default ContactForm
