import React, { Component } from 'react'
import LeftPane from '../LeftPane/index.jsx'
import RightPane from '../RightPane/index.jsx'
// import navIcon from '../../assets/menu.svg'

class LandingPage extends Component {
  render () {
    // window.addEventListener('resize', () => {
    //   this.screenLogic()
    // })

    return (
      <div className='landing_page'>
        <LeftPane>
          <nav>
            <a href='#aboutPage'>About</a>
            <a href='#interestPage'>Interest</a>
            <a href='#contactPage'>Contact</a>
          </nav>
        </LeftPane>
        <div className='landing_main_content'>
          <div className='main_text_container'>
            <p>Work In</p>
            <p>Progress</p>
          </div>
        </div>
        <RightPane />
      </div>
    )
  }
}

export default LandingPage
