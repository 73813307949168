import React from 'react'
import downArrow from '../../assets/slim-down.svg'

const RightPane = props => {
  if (props.invert) {
    return (
      <div className='right_pane invert'>
        <img src={downArrow} alt='' />
        <p>scroll</p>
      </div>
    )
  } else {
    return (
      <div className='right_pane'>
        <p>scroll</p>
        <img src={downArrow} alt='' />
      </div>
    )
  }
}

export default RightPane
