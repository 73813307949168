// TODO: Convert Objects to Function
// TODO: Download photos and find a way to reference them.
import akira from '../assets/interests/akira.jpg'
import andersonPaakMalibu from '../assets/interests/andersonpaak-malibu.jpg'
import attackontitan from '../assets/interests/attackontitan.jpg'
import berserk from '../assets/interests/berserk.jpg'
import codegeass from '../assets/interests/codegeass.jpg'
import domkennedyyellowalbum from '../assets/interests/domkennedy-yellowalbum.jpg'
import fullmetalalchemist from '../assets/interests/fullmetalalchemist.jpg'
import gameofthrones from '../assets/interests/gameofthrones.jpg'
import graveofthefireflies from '../assets/interests/graveofthefireflies.jpg'
import jayzreasonabledoubt from '../assets/interests/jayz-reasonabledoubt.jpg'
import joeybadassallamerikkanbadass from '../assets/interests/joeybadass-allamerikkanbadass.jpg'
import kanyewestcollegedropout from '../assets/interests/kanyewest-collegedropout.jpg'
import kendricklamargoodkidmaadcity from '../assets/interests/kendricklamar-goodkidmaadcity.jpg'
import kidcudimanonthemoon from '../assets/interests/kidcudi-manonthemoon.jpg'
import majidjordanthespacebetween from '../assets/interests/majidjordan-thespacebetween.jpg'
import outkastaquemini from '../assets/interests/outkast-aquemini.jpeg'
import paprika from '../assets/interests/paprika.jpg'
import rememberthetitans from '../assets/interests/rememberthetitans.jpeg'
import samuraichamploo from '../assets/interests/samuraichamploo.jpg'
import spiritedaway from '../assets/interests/spiritedaway.jpg'
import tameimpalacurrents from '../assets/interests/tameimpala-currents.jpg'
import tokyoghoul from '../assets/interests/tokyoghoul.jpg'
import toroymoinothinginreturn from '../assets/interests/toroymoi-nothinginreturn.jpg'
import travisscottastroworld from '../assets/interests/travisscott-astroworld.jpg'
import twinshadowcaer from '../assets/interests/twinshadow-caer.jpg'
import atlantis from '../assets/interests/atlantisTheLostEmpire.jpg'
import queenandslim from '../assets/interests/queenandslim.jpeg'
import dragonballz from '../assets/interests/dragonballz.jpg'
import pokemon2000 from '../assets/interests/pokemon2000.png'
import jeopardy from '../assets/interests/jeopardy.jpg'

let newAudio = (name, imgURL) => {
  return {
    type: 'audio',
    name: name,
    imgURL: imgURL
  }
}
let newVideo = (name, imgURL) => {
  return {
    type: 'video',
    name: name,
    imgURL: imgURL
  }
}
let audioInterestsArray = [
  newAudio('Anderson.Paak - Malibu', andersonPaakMalibu),
  newAudio('Dom Kennedy - Yellow Album', domkennedyyellowalbum),
  newAudio('Jay Z - Reasonable Doubt', jayzreasonabledoubt),
  newAudio('Joey Bada$$ - All Amerikkan Bada$$', joeybadassallamerikkanbadass),
  newAudio('Kanye West - The College Dropout', kanyewestcollegedropout),
  newAudio(
    'Kendrick Lamar - Good Kid M.A.A.D. City',
    kendricklamargoodkidmaadcity
  ),
  newAudio('Kid Cudi - Man on the Moon', kidcudimanonthemoon),
  newAudio('Majid Jordan', majidjordanthespacebetween),
  newAudio('Outkast - Aquemini', outkastaquemini),
  newAudio('Tame Impala - Currents', tameimpalacurrents),
  newAudio('Toro y Moi - Nothing in Return', toroymoinothinginreturn),
  newAudio('Travis Scott', travisscottastroworld),
  newAudio('Twin Shadow', twinshadowcaer)
]

let videoInterestsArray = [
  newVideo('Akira', akira),
  newVideo('Attack on Titan', attackontitan),
  newVideo('Berserk', berserk),
  newVideo('Code Geass', codegeass),
  newVideo('DragonBall Z', dragonballz),
  newVideo('Full Metal Alchemist: Brotherhood', fullmetalalchemist),
  newVideo('Game of Thrones', gameofthrones),
  newVideo('Grave of the Fireflies', graveofthefireflies),
  newVideo('Jeopardy', jeopardy),
  newVideo('Paprika', paprika),
  newVideo('Remember the Titans', rememberthetitans),
  newVideo('Samurai Champloo', samuraichamploo),
  newVideo('Spirited Away', spiritedaway),
  newVideo('Tokyo Ghoul', tokyoghoul),
  newVideo('Atlantis: The Lost Empire', atlantis),
  newVideo('Queen & Slim', queenandslim),
  newVideo('Pokemon 2000', pokemon2000)
]

export { videoInterestsArray, audioInterestsArray }
