import React from 'react'
// import menu from '../../assets/menu.svg'
// import forbidden from '../../assets/forbidden.svg'
// import '../../App.css'

const LeftPane = props => {
  return (
    <div className='left_pane'>
      {props.children}
      {/* <img className='forbidden_icon' src={forbidden} alt='' /> */}
    </div>
  )
}

export default LeftPane
