// Add frame for sports
import React, { Component } from 'react'
import LeftPane from '../LeftPane/index'
// import RightPane from '../RightPane/index'
import InterestBox from '../InterestBox/index'

class Interest extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div id='interestPage' className='interest_page'>
        <LeftPane>
          <div className='page_title'>
            <p>I N T E R E S T S</p>
          </div>
        </LeftPane>
        <div className='interest_page_content'>
          <div className='outside_row' id='row1'>
            <span />
          </div>
          <div id='row2'>
            <div className='interest_box_container'>
              <InterestBox />
            </div>
            <div>
              Born in the early 90's, my childhood was similar to many 90's
              kids. When I wasn't playing outside with my cousins and friends, I
              was inside with them playing video games and watching DragonBall
              Z. It's no surprise that these are some of the things that stuck
              with me over the years. To the left is a short list of some of
              shows and music that have shaped me in some way. I look forward to
              expanding this section's capabilities and providing more details
              as to why I selected these items.
              {/* Music
              <br />
              Technology
              <br />
              Anime
              <br />
              Sports
              <br />
              Movies
              <br />
              Fashion */}
            </div>
          </div>
          <div className='outside_row' id='row3'>
            <span />
          </div>
        </div>
      </div>
    )
  }
}

export default Interest
