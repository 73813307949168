import React, { Component } from 'react'
import LeftPane from '../LeftPane/index'

class About extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div id='aboutPage' className='about_page'>
        <LeftPane>
          <div className='page_title'>
            <p>A B O U T</p>
          </div>
        </LeftPane>
        <div className='about_page_content'>
          <div>
            {/* This should be about Matthew */}
            <p>
              I am a self-taught web developer currently living in Toronto
              Canada. My current strengths lie within Front End Development with
              a specialization in React JS. One of my future goals is to become
              a Full Stack Developer, hopefully by 2021. My journey into web
              development was sparked by an idea to create my own mobile
              application. Although the project was not completed, I have
              learned many invaluable skills through this process and I look
              forward to applying my skill set to future projects.
            </p>
          </div>
          <div>
            <p>
              {/* This should be about the website */}
              This website was built with the intention to not only be my
              portfolio that will house my work but also a project I can iterate
              over and add new features to. I am currently using React JS, which
              my seem unnecessary at the moment, however the goal is to
              eventually integrate a Mongo Database with Express and GraphQL.
              This is the one project that I actually do not see myself
              finishing. It will always be a work in progress.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default About
