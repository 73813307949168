// TODO: Update site so that images transition smoothly.
import React, { Component } from 'react'
import {
  videoInterestsArray,
  audioInterestsArray
} from '../../resources/objects.js'

class InterestBox extends Component {
  constructor (props) {
    super(props)

    this.state = {
      styleArray: [],
      elementArray: []
    }
    this.generateFourDifferentStyles = this.generateFourDifferentStyles.bind(
      this
    )
  }

  getRandomIntInclusive = (min, max) => {
    // A function created to select a random number, in between two numbers (min & max)
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  onItemHover = event => {
    // This is what happens when someone hovers over the item
    // event.persist()
    // let currentStyle = event.target.style
    // let hoverStyle = {}
    // let updatedStyle = Object.assign(currentStyle, hoverStyle)
  }

  generateFourDifferentStyles = () => {
    // This is the function responsible for styling the four boxes. Style array should only have four elements in the array. The first two elements are for the video images and the last two are for the audio.

    let styleArray = []
    let elementArray = []

    let generateArrayIndices = array => {
      // This is where I select the items. First I generate two random number and then compare them to each other. If the numbers are the same, we generate another two numbers until they are different. These will be the two indices from which the item is selected from the array passed in.

      let var1 = this.getRandomIntInclusive(0, array.length - 1)
      let var2 = this.getRandomIntInclusive(0, array.length - 1)
      if (var1 === var2) {
        generateArrayIndices(array)
      } else {
        // Once numbers are different, items from the array are selected and pushed to the style array.
        styleArray.push(array[var1], array[var2])
      }
    }
    generateArrayIndices(videoInterestsArray)
    generateArrayIndices(audioInterestsArray)

    // Possibly Break this into another component or something.
    let createStyleRules = () => {
      // Take each style item and create the style rules for the element. Then push it to the element
      styleArray.forEach((item, index) => {
        // console.log(item)
        let backgroundImageVariable = `url(${item.imgURL})`

        let styleObject = {
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: backgroundImageVariable,
          backgroundPosition: 'center',
          transition: '1s'
        }
        elementArray.push(
          <div
            key={item.name}
            id={item.name}
            className='interestbox-item'
            style={styleObject}
            onMouseOver={e => {
              this.onItemHover(e)
            }}
          />
        )
      })
    }
    createStyleRules()

    this.setState({ elementArray })
  }
  timer = () => {
    let generation = () => this.generateFourDifferentStyles()
    generation()
    this.intervalID = setInterval(function () {
      generation()
    }, 5000)
  }
  mouseEnter = event => {
    clearInterval(this.intervalID)
    clearInterval(this.secondIntervalID)
  }
  mouseLeave = event => {
    let trigger = () => this.timer()
    this.secondIntervalID = setTimeout(function () {
      trigger()
    }, 2000)
  }
  componentDidMount () {
    this.timer()
  }
  render () {
    return (
      <div
        className='interest_box_wrapper'
        onMouseEnter={e => this.mouseEnter(e)}
        onMouseLeave={e => this.mouseLeave(e)}
      >
        {this.state.elementArray}
      </div>
    )
  }
}

export default InterestBox
